import React from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import axios from "../util/axios";
import "./login.css"
import { Footer } from "./App.js";
import logoIcon from '../components/assets/ebbi.rocks.logo.icon.png';
import googleLogoIcon from '../google/assets/g-logo.png';

import { GoogleOAuthProvider, useGoogleLogin, GoogleLogin } from '@react-oauth/google';
import moment from "moment-timezone";

export default function Login() {
  const navigate = useNavigate();

  const { user, login, logout } = useAuth();

  if (user) {
    // user is not authenticated
    return <Navigate to="/" />;
  }

  return (
    // div full screen
    <div>
      <div className=" h-full" >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>

          <div className='grid place-content-center text-center pt-40 text-ebbidark space-y-3'>

            <div>
              <a href="https://ebbi.rocks"><img src={logoIcon} alt="Logo" className="h-14 m-auto inline-block" /></a>
            </div>

            <h2 className="text-2xl">Access to Ebbi.Rocks</h2>

            <div className="google-login-container p-5 rounded-md m-3">
              <LoginButton login={login} />
            </div>

            <h1 className="">“Simple things are the most valuable and only wise people appreciate them.”</h1>
            <h1 className="">Ebbi.Rocks is nothing more, nothing less. </h1>
            <h1 className=" italic">Well... more is to come.</h1>

            {/* <div className=" flex items-center space-x-3 text-2xl justify-center text-ebbired-100"><Heart /><Sweat /><Earth /></div> */}
          </div>

        </GoogleOAuthProvider>
      </div>
      <Footer />
    </div>

  )
}



function LoginButton({ login }) {
  const googleLogin = useGoogleLogin({
    // onNonOAuthError: error => { alert("Something went wrong, please try again later"); },
    onError: error => { alert("Something went wrong, please try again later"); },
    onSuccess: codeResponse => {
      const timezone = moment.tz.guess();

      axios.post('/auth/google', { time_zone: timezone, google_credentials: codeResponse })
        .then((response) => {
          login(response.data.tokens.bearer)
        })
        .catch((error) => {
          alert("Something went wrong, please try again later");
        })
    },
    flow: 'implicit',
  });

  return <button onClick={googleLogin} className='px-4 py-2 text-ebbidark bg-white border border-ebbigrey-200 hover:border-ebbigrey-300 hover:shadow-sm rounded-md text-md'>
    <img src={googleLogoIcon} className="inline-block w-8 mr-3" />
    Sign in with Google
  </button>;
}

function Heart(props) {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M923 283.6a260.04 260.04 0 00-56.9-82.8 264.4 264.4 0 00-84-55.5A265.34 265.34 0 00679.7 125c-49.3 0-97.4 13.5-139.2 39-10 6.1-19.5 12.8-28.5 20.1-9-7.3-18.5-14-28.5-20.1-41.8-25.5-89.9-39-139.2-39-35.5 0-69.9 6.8-102.4 20.3-31.4 13-59.7 31.7-84 55.5a258.44 258.44 0 00-56.9 82.8c-13.9 32.3-21 66.6-21 101.9 0 33.3 6.8 68 20.3 103.3 11.3 29.5 27.5 60.1 48.2 91 32.8 48.9 77.9 99.9 133.9 151.6 92.8 85.7 184.7 144.9 188.6 147.3l23.7 15.2c10.5 6.7 24 6.7 34.5 0l23.7-15.2c3.9-2.5 95.7-61.6 188.6-147.3 56-51.7 101.1-102.7 133.9-151.6 20.7-30.9 37-61.5 48.2-91 13.5-35.3 20.3-70 20.3-103.3.1-35.3-7-69.6-20.9-101.9z" />
    </svg>
  );
}


function Earth(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M266.3 48.3l-33.8 25.3c-5.4 4-8.5 10.4-8.5 17.1v9.1c0 6.8 5.5 12.3 12.3 12.3 2.4 0 4.8-.7 6.8-2.1l41.8-27.9c2-1.3 4.4-2.1 6.8-2.1h1c6.2 0 11.3 5.1 11.3 11.3 0 3-1.2 5.9-3.3 8l-19.9 19.9a53.03 53.03 0 01-20.7 12.8l-26.5 8.8c-5.8 1.9-9.6 7.3-9.6 13.4 0 3.7-1.5 7.3-4.1 10L202 182.1c-6.4 6.4-9.9 15-9.9 24v4.3c0 16.4 13.6 29.7 29.9 29.7 11 0 21.2-6.2 26.1-16l4-8.1c2.4-4.8 7.4-7.9 12.8-7.9 4.5 0 8.7 2.1 11.4 5.7l16.3 21.7c2.1 2.9 5.5 4.5 9.1 4.5 8.4 0 13.9-8.9 10.1-16.4l-1.1-2.3c-3.5-7 0-15.5 7.5-18l21.2-7.1c7.6-2.5 12.7-9.6 12.7-17.6 0-10.3 8.3-18.6 18.6-18.6H400c8.8 0 16 7.2 16 16s-7.2 16-16 16h-20.7c-7.2 0-14.2 2.9-19.3 8l-4.7 4.7c-2.1 2.1-3.3 5-3.3 8 0 6.2 5.1 11.3 11.3 11.3h11.3c6 0 11.8 2.4 16 6.6l6.5 6.5c1.8 1.8 2.8 4.3 2.8 6.8s-1 5-2.8 6.8l-7.5 7.5c-3.6 3.8-5.6 8.7-5.6 13.8s2 10 5.7 13.7L408 304c10.2 10.2 24.1 16 38.6 16h7.4c6.5-20.2 10-41.7 10-64 0-111.4-87.6-202.4-197.7-207.7zm172 307.9c-3.7-2.6-8.2-4.1-13-4.1-6 0-11.8-2.4-16-6.6L396 332c-7.7-7.7-18-12-28.9-12-9.7 0-19.2-3.5-26.6-9.8L314 287.4c-11.6-9.9-26.4-15.4-41.6-15.4h-21c-12.6 0-25 3.7-35.5 10.7L188.5 301c-17.8 11.9-28.5 31.9-28.5 53.3v3.2c0 17 6.7 33.3 18.7 45.3l16 16c8.5 8.5 20 13.3 32 13.3H248c13.3 0 24 10.7 24 24 0 2.5.4 5 1.1 7.3 71.3-5.8 132.5-47.6 165.2-107.2zM512 256c0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0s256 114.6 256 256zM187.3 100.7c-6.2-6.2-16.4-6.2-22.6 0l-32 32c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0l32-32c6.2-6.2 6.2-16.4 0-22.6z" />
    </svg>
  );
}

function Sweat(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M476.8 126.3c-4.1 1.1-8.4 1.7-12.8 1.7-26.5 0-48-21-48-47 0-5 1.8-11.3 4.6-18.1l.9-2.1c9-20.2 26.5-44.9 36-57.5 3.2-4.4 9.6-4.4 12.8 0C483.4 20.6 512 61 512 81c0 21.7-14.9 39.8-35.2 45.3zM256 0c51.4 0 99.3 15.2 139.4 41.2-1.5 3.1-3 6.2-4.3 9.3-3.4 8-7.1 19-7.1 30.5 0 44.3 36.6 79 80 79 9.6 0 18.8-1.7 27.4-4.8 13.3 30.9 20.6 65 20.6 100.8 0 141.4-114.6 256-256 256S0 397.4 0 256 114.6 0 256 0zm127.8 317.8C345.3 329.4 301.9 336 256 336s-89.3-6.6-127.8-18.2c-12.3-3.7-24.3 7-19.2 18.7 24.5 56.9 81.1 96.7 147 96.7s122.5-39.8 147-96.7c5.1-11.8-6.9-22.4-19.2-18.7zm-166.2-89c2.1 2.8 5.7 3.9 8.9 2.8s5.5-4.1 5.5-7.6c0-17.9-6.7-35.6-16.6-48.8-9.8-13-23.9-23.2-39.4-23.2s-29.6 10.2-39.4 23.2c-9.9 13.2-16.6 30.9-16.6 48.8 0 3.4 2.2 6.5 5.5 7.6s6.9 0 8.9-2.8l.2-.2c.2-.2.4-.5.7-.9.6-.8 1.6-2 2.8-3.4 2.5-2.8 6-6.6 10.2-10.3 8.8-7.8 18.8-14 27.7-14s18.9 6.2 27.7 14c4.2 3.7 7.7 7.5 10.2 10.3 1.2 1.4 2.2 2.6 2.8 3.4.3.4.6.7.7.9l.2.2zm160 0c2.1 2.8 5.7 3.9 8.9 2.8s5.5-4.1 5.5-7.6c0-17.9-6.7-35.6-16.6-48.8-9.8-13-23.9-23.2-39.4-23.2s-29.6 10.2-39.4 23.2c-9.9 13.2-16.6 30.9-16.6 48.8 0 3.4 2.2 6.5 5.5 7.6s6.9 0 8.9-2.8l.2-.2c.2-.2.4-.5.7-.9.6-.8 1.6-2 2.8-3.4 2.5-2.8 6-6.6 10.2-10.3 8.8-7.8 18.8-14 27.7-14s18.9 6.2 27.7 14c4.2 3.7 7.7 7.5 10.2 10.3 1.2 1.4 2.2 2.6 2.8 3.4.3.4.6.7.7.9l.2.2z" />
    </svg>
  );
}

function Enter(props) {
  return (
    <svg
      viewBox="0 0 512 512"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M160 136v104h153.37l-52.68-52.69a16 16 0 0122.62-22.62l80 80a16 16 0 010 22.62l-80 80a16 16 0 01-22.62-22.62L313.37 272H160v104a56.06 56.06 0 0056 56h208a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56H216a56.06 56.06 0 00-56 56zM48 240a16 16 0 000 32h112v-32z" />
    </svg>
  );
}
