// user context
import axios from "../util/axios";
import * as React from "react";
import { createContext, useContext, useMemo, useState, useReducer } from "react";
import { useAuth } from "../hooks/useAuth";
import { useNavigate } from 'react-router-dom';
import { googleLogout } from '@react-oauth/google';
import { get } from 'superagent';
import ReactGA4 from 'react-ga4';

const UserProfileContext = createContext();
const UserProfileDispatchContext = createContext();

// reducer for user profile that keeps default unit, youtube bool, and sessionUnit
const userProfileReducer = (state, action) => {
    switch (action.type) {
        case 'setProfile':
            // get from a list of providers in action.payload.providers one that is type youtube
            let youtube = action.payload.providers.find(provider => provider.type === "youtube");
            if (!youtube) {
                youtube = null;
            }  else {
                youtube = youtube.configuration;
            }
            
            // set google analytics user id
            ReactGA4.set({ userId: action.payload.id });

            return { 
                id: action.payload.id,
                firstName: action.payload.first_name,
                lastName: action.payload.last_name,
                email: action.payload.email,
                avatarURL: action.payload.avatar_url,
                youtube: youtube,
                defaultSpacing: action.payload.default_spacing, 
                sessionUnit: action.payload.default_spacing,
                timeZone: action.payload.time_zone
             };
        case 'setYoutube':
            return { ...state, youtube: action.payload };
        case 'setDefaultSpacing':
            return { ...state, defaultSpacing: action.payload.defaultSpacing, sessionUnit: action.payload.defaultSpacing };
        case 'setSessionUnit':
            return { ...state, sessionUnit: action.payload.sessionUnit };
        case 'setTimeZone':
            return { ...state, timeZone: action.payload.sessionUnit };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};


// async function to get profile from api
export const getProfile = async (token) => {
    return axios.get('/api/profile', { headers: { Authorization: "Bearer " + token } });
}



export const UserProfileProvider = ({ children }) => {

    // reducer
    const [userProfile, dispatch] = useReducer(userProfileReducer, null);

    const auth = useAuth();

    const navigate = useNavigate()

    React.useEffect(() => {
        getProfile(auth.user)
            .then((response) => {
                dispatch({type: "setProfile", payload: response.data})
            }).catch((error) => {
                // if 401 (unauthorized) then redirect to login
                if (error.response.status === 401) {
                    auth.logout();
                    googleLogout();
                    navigate('/login');
                } else {
                    alert("Something went wrong, please try again later");
                }
            })
    }, [])

    return (
        <UserProfileContext.Provider value={userProfile}>
            <UserProfileDispatchContext.Provider value={dispatch}>
                {userProfile ? children : "loading"}
            </UserProfileDispatchContext.Provider>
        </UserProfileContext.Provider>
    );
};

export const useUserProfile = () => {
    return useContext(UserProfileContext);
};

export const useUserProfileDispatch = () => {
    return useContext(UserProfileDispatchContext);
}
