import axios from 'axios';
import AuthorizeYoutube from '../google/AuthorizeYoutube';
import { GoogleOAuthProvider } from '@react-oauth/google';

import { useUserProfile } from '../contexts/user';

import * as React from "react";
import {
    Outlet
} from "react-router-dom";
import Navigation from '../components/Navigation';


import { useAuth } from '../hooks/useAuth';
import { ProtectedRoute } from '../router/ProtectedRoute';
import { UserProfileProvider } from '../contexts/user';


// dashboard component with navigation and outlet
export default function App() {

    return (
        <UserProfileProvider>
            <ProtectedRoute>
                <Navigation />
                <Outlet />
                <Footer />
            </ProtectedRoute>
        </UserProfileProvider>
    )
}



export function Footer() {
    return (
        <div className="bg-gradient-to-b from-ebbigrey-200 to-ebbigrey-300 text-grey text-center p-5 [text-shadow:_0_1px_0_rgb(255_255_255_/_40%)]" style={{ position: "absolute", bottom: 0, width: "100%" }}>
            <div className="mx-auto max-w-7xl">
                <div className="text-xs">
                    <div className="flex flex-col sm:flex-row justify-center">
                        <div className="mx-2 my-2 sm:my-0">
                            <a href="https://ebbi.rocks" className="hover:text-ebbilight">Ebbi.Rocks</a>
                        </div>
                        <div className="mx-2 my-2 sm:my-0">
                            <a href="https://ebbi.rocks/legal/privacy.html" target='_blank' className="hover:text-ebbilight">Privacy Policy</a>
                        </div>
                        <div className="mx-2 my-2 sm:my-0">
                            <a href="https://ebbi.rocks/legal/google.html" target='_blank' className="hover:text-ebbilight">Google API Policy (Youtube access)</a>
                        </div>
                        <div className="mx-2 my-2 sm:my-0">
                            <a href="https://ebbi.rocks/legal/terms.html" target='_blank' className="hover:text-ebbilight">Terms and Conditions</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
