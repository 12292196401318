import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import Router from './router/Router';
import ReactGA4 from 'react-ga4';

ReactGA4.initialize(process.env.REACT_APP_GA4_MEASUREMENT_ID); 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <Router />
  // </React.StrictMode>  
);
