import React from "react";
import YoutubeSettings from "./Youtube";
import { useUserProfile, useUserProfileDispatch } from "../../contexts/user";
import { useState, useRef } from 'react';
import { Switch } from '@headlessui/react';
import { useAuth } from "../../hooks/useAuth";
import axios from "../../util/axios";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

import moment from "moment-timezone";





export default function Settings() {

    const auth = useAuth();

    const profile = useUserProfile();
    const dispatch = useUserProfileDispatch();


    // axiox patch request to update the default unit
    const updateDefaultSpacing = (unit) => {

        axios.patch("/api/profile", { default_spacing: unit }, { headers: { Authorization: "Bearer " + auth.user } }).then((response) => {
            // TODO: profile not reloaded but it will be on refresh
        }).catch((error) => {
            alert("Something went wrong, please try again later");
        }
        );
    }


    const update = (unit) => {
        dispatch({ type: "setDefaultSpacing", payload: { defaultSpacing: unit } });
        updateDefaultSpacing(unit);
    }



    return (
        <>
            <GeneralSettings profile={profile} update={update} />
            <YoutubeSettings />
        </>
    )


}

function GeneralSettings({ profile, update }) {
    return (
        <div className="my-5 mx-auto max-w-7xl rounded-md">
            <div className="flex w-full flex-col items-start justify-start rounded-md border border-ebbigrey-200 bg-ebbigrey-100">
                <div className="w-full items-start px-5 py-3 bg-ebbidark rounded-t-md">
                    <span className='text-ebbilight text-xl'>General Settings</span>
                </div>
                <Toggle defaultSpacing={profile.defaultSpacing} setDefaultSpacing={update} />
                <TimezoneList />
            </div>
        </div>
    );
}


function Toggle({ defaultSpacing, setDefaultSpacing }) {
    return (
        <div className="max-w-7xl flex p-4 justify-start items-center">
            <span className="text-sm font-bold">Default system setting:</span>
            <span className="text-sm px-2">Days</span>
            <Switch
                checked={defaultSpacing}
                onChange={() => setDefaultSpacing(defaultSpacing === "day" ? "week" : "day")}
                className={`bg-ebbiyellow relative inline-flex h-[28px] w-[52px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 shadow-inner`}
            >
                <span className="sr-only">Use setting</span>
                <span
                    aria-hidden="true"
                    className={`${defaultSpacing === "week" ? 'translate-x-6' : 'translate-x-0'}
              pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                />
            </Switch>
            <span className="text-sm pl-2">Weeks</span>
        </div>
    )
}


// component that lists the timezones from the moment timezone library
function TimezoneList() {
    const timezones = moment.tz.names();

    const auth = useAuth();

    const profile = useUserProfile();
    const dispatch = useUserProfileDispatch();

    const [selectedItem, setSelectedItem] = useState(moment.tz.guess());
    const [saving, setSaving] = useState(false);



    const updateTimeZone = (timeZone) => {
        axios.patch("/api/profile", { time_zone: timeZone }, { headers: { Authorization: "Bearer " + auth.user } }).then((response) => {
            // TODO: profile not reloaded but it will be on refresh
        }).catch((error) => {
            alert("Something went wrong, please try again later");
        }
        ).finally(() => {
            setSaving(false);
        })
    }


    const update = (timeZone) => {
        setSaving(true);
        dispatch({ type: "setTimeZone", payload: { timeZone: timeZone } });
        updateTimeZone(timeZone);
    }

  
    return (

        <div className="flex flex-col w-full items-start gap-2 p-4">
            <div className="text-sm font-bold">Change your timezone setting:</div>
            <div className="flex flex-col justify-center items-start w-full md:w-1/3 relative">

                <select className="h-10 w-full rounded-sm border-r-8 border-transparent px-2  text-ebbidark2 outline outline-ebbigrey-200"
                    value={profile.timeZone}
                    onChange={event => update(event.target.value)}

                >
                    <option value="">Your timezone</option>
                    {timezones.map((timezone) => (
                        <option key={timezone} value={timezone}>
                            {timezone + " [" + moment.tz(timezone).format('Z ') + "]"}
                        </option>
                    ))}
                </select>

                <div className='text-sm mt-1'>
                    {saving ? "Saving..." : "Will be autosaved."}
                </div>
            </div>
        </div>
    )

}
