import axios from '../../util/axios';
import { useAuth } from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { Listbox } from '@headlessui/react'
import { Tab } from '@headlessui/react'
import { Disclosure, Switch } from '@headlessui/react'
import { ChevronUpIcon, ChevronDownIcon, ArrowDownIcon, TvIcon, CheckCircleIcon, ChevronDoubleDownIcon } from '@heroicons/react/20/solid'
import Moment from 'react-moment';
import AuthorizeYoutube from '../../google/AuthorizeYoutube';

import { useUserProfile, useUserProfileDispatch } from "../../contexts/user";

import { useNavigate } from 'react-router-dom';

// import { youtube } from 'googleapis/build/src/apis/youtube';




export default function YoutubeSettings() {
    const auth = useAuth();

    const profile = useUserProfile();

    // const [playlists, setPlaylists] = useState(profile.youtube.playlists);

    let playlists = [];

    if (profile.youtube) {
        playlists = profile.youtube.playlists;
    }


    const [loading, setLoading] = useState(false);
    const [tracking, setTracking] = useState(false);
    const [selectedItem, setSelectedItem] = useState("");
    const [untracking, setUntracking] = useState(null);


    const dispatch = useUserProfileDispatch();

    const navigate = useNavigate()

    const reloadProfile = () => {
        setLoading(true);
        axios.get('/api/profile', { headers: { Authorization: "Bearer " + auth.user } })
            .then((response) => {
                let youtube = response.data.providers.find(provider => provider.type === "youtube");
                if (youtube) {
                    dispatch({
                        type: "setYoutube", payload: youtube.configuration
                    })
                }
            })
            .catch((error) => {
                // if 401 (unauthorized) then redirect to login
                if (error.response.status === 401) {
                    auth.logout();
                    // googleLogout();
                    navigate('/login');
                } else {
                    alert("Something went wrong, please try again later");
                }

            })
            .finally(() => {
                setTimeout(() => {
                    setLoading(false);
                }, 200);
            })

    }

    const trackPlaylist = () => {
        setTracking(true);
        axios.patch('/api/youtube/' + profile.youtube.id + '/playlist/' + selectedItem + '?patch=is_tracked,is_included_in_mail', { is_tracked: true, is_included_in_mail: true }, { headers: { Authorization: "Bearer " + auth.user } })
            .then((response) => {
                setTracking(false);

                // todo refactor
                axios.get('/api/profile', { headers: { Authorization: "Bearer " + auth.user } })
                    .then((response) => {
                        let youtube = response.data.providers.find(provider => provider.type === "youtube");
                        if (youtube) {
                            dispatch({
                                type: "setYoutube", payload: youtube.configuration
                            })
                        }
                    })
                    .catch((error) => {
                        // if 401 (unauthorized) then redirect to login
                        if (error.response.status === 401) {
                            auth.logout();
                            // googleLogout();
                            navigate('/login');
                        } else {
                            alert("Something went wrong, please try again later");
                        }

                    })

                setSelectedItem('');
            })
            .catch((error) => {
                alert("Something went wrong, please try again later");

                alert('Error updating include in mail');
            })

        // axios.post('/api/youtube/playlist', { provider_id: profile.youtube.id, youtube_playlist_id: selectedItem }, { headers: { Authorization: "Bearer " + auth.user } })
        //     .then((response) => {
        //         setTracking(false);


        //         // todo refactor
        //         axios.get('/api/profile', { headers: { Authorization: "Bearer " + auth.user } })
        //             .then((response) => {
        //                 let youtube = response.data.providers.find(provider => provider.type === "youtube");
        //                 if (youtube) {
        //                     console.log(youtube+ "youtube oleeeee - 1");
        //                     dispatch({
        //                         type: "setYoutube", payload: youtube.configuration
        //                     })
        //                 }
        //             })
        //             .catch((error) => {
        //                 // if 401 (unauthorized) then redirect to login
        //                 if (error.response.status === 401) {
        //                     auth.logout();
        //                     // googleLogout();
        //                     navigate('/login');
        //                 }

        //                 console.log("aaaa" + error);
        //             })




        //         setSelectedItem('');
        //     })
        //     .catch((error) => {
        //         alert("Something went wrong, please try again later");

        //         alert('Error tracking the playlist');
        //     }).finally(() => {
        //         setTracking(false);
        //     })
    };

    const untrackPlaylist = (playlistID) => {
        // confirm 
        if (!window.confirm("Are you sure you want to untrack this playlist?")) {
            return;
        }

        setUntracking(playlistID);
        axios.patch('/api/youtube/' + profile.youtube.id + '/playlist/' + playlistID + '?patch=is_tracked', { is_tracked: false }, { headers: { Authorization: "Bearer " + auth.user } })
            .then((response) => {

                // todo refactor
                axios.get('/api/profile', { headers: { Authorization: "Bearer " + auth.user } })
                    .then((response) => {
                        let youtube = response.data.providers.find(provider => provider.type === "youtube");
                        if (youtube) {
                            dispatch({
                                type: "setYoutube", payload: youtube.configuration
                            })
                        }
                    })
                    .catch((error) => {
                        // if 401 (unauthorized) then redirect to login
                        if (error.response.status === 401) {
                            auth.logout();
                            // googleLogout();
                            navigate('/login');
                        }else {
                            alert("Something went wrong, please try again later");
                        }
                    })

                setSelectedItem('');
            })
            .catch((error) => {
                alert("Something went wrong, please try again later");

                alert('Error updating include in mail');
            })
            .finally(() => {
                setUntracking(null);
            })


    };

    // update include in mail
    const updateIsIncludedInMail = (playlistID, isIncludedInMail) => {
        axios.patch('/api/youtube/' + profile.youtube.id + '/playlist/' + playlistID + '?patch=is_included_in_mail', { is_included_in_mail: isIncludedInMail }, { headers: { Authorization: "Bearer " + auth.user } })
            .then((response) => {
                const newData = playlists.map(item => {
                    if (item.id === playlistID) {
                        item.is_included_in_mail = isIncludedInMail;
                    }
                    return item;
                });
                // setPlaylists(newData);
                reloadProfile();
            })
            .catch((error) => {
                alert("Something went wrong, please try again later");

                alert('Error updating include in mail');
            })
    };

    // sync
    const syncPlaylists = () => {
        axios.post('/api/youtube/' + profile.youtube.id + '/synchronize', null, { headers: { Authorization: "Bearer " + auth.user } })
            .then((response) => {
                reloadProfile();
            })
            .catch((error) => {
                alert("Something went wrong, please try again later");

                alert('Error syncing playlists');
            })
    };



    // useEffect(() => {
    //     if (profile.youtube) { setPlaylists(profile.youtube.playlists); }
    // }, [])

    var enabled = true;


    // calculate step based on the profile 
    var step = 1;
    if (profile.youtube) {
        step = 2;
        if (profile.youtube.has_channel) {
            step = 3;
            if (profile.youtube.playlists.length) {
                step = 4;
                if (profile.youtube.playlists.some(item => item.is_tracked)) {
                    step = 5;
                }
            }
        }
    }

    return (
        <div className="my-5 mx-auto max-w-7xl rounded-md">
            <div className="flex w-full flex-col items-start justify-start rounded-md border border-ebbigrey-200 bg-ebbigrey-100 pb-4">


                <div className="w-full items-start px-5 py-3 bg-[#b2071d] rounded-t-md">
                    <span className='text-white text-xl'>Youtube Settings</span>
                </div>


                {(step < 4) && (<div className='w-full'>
                    <div className="flex text-center w-full font-medium text-md">
                        <div className={"flex-grow border border-ebbigrey-200 p-2 " + ((step > 1) && " bg-ebbigrey-300 bg-opacity-30 text-ebbigrey-400 ") + ((step == 1) && " underline border-b-0 ")}>
                            <div className="hidden md:block">{(step > 1) ? <div className='text-ebbigrey-300'><CheckCircleIcon className='text-ebbigreen-100 inline h-5 relative bottom-[2px] pr-1' />Ebbi.Rocks Authorized</div> : "Authorize Ebbi.Rocks"}</div>
                            <div className="md:hidden">{(step > 1) ? <div className='text-ebbigrey-300'>Authorized<CheckCircleIcon className='text-ebbigreen-100 inline h-5 relative bottom-[2px] pr-1' /></div> : "Authorize"}</div>
                        </div>
                        <div className={"flex-grow border border-ebbigrey-200 p-2 " + ((step > 2) && " bg-ebbigrey-300 bg-opacity-30 text-ebbigrey-400 ") + ((step == 2) && " underline border-b-0 ") + ((step < 2) && " text-ebbigrey-300 bg-ebbigrey-200 ")}>
                            <div className="hidden md:block">{(step > 2) ? <div className='text-ebbigrey-300'><CheckCircleIcon className='text-ebbigreen-100 inline h-5 relative bottom-[2px] pr-1' />Have a Channel</div> : "Create a Channel"}</div>
                            <div className="md:hidden">{(step > 2) ? <div className='text-ebbigrey-300'>Channel<CheckCircleIcon className='text-ebbigreen-100 inline h-5 relative bottom-[2px] pr-1' /></div> : "Channel"}</div>
                        </div>
                        <div className={"flex-grow border border-ebbigrey-200 p-2 " + ((step == 3) && " underline border-b-0 ") + ((step < 3) && " text-ebbigrey-300 bg-ebbigrey-200 ")}>
                            <div className="hidden md:block">Create a playlist</div>
                            <div className="md:hidden">Playlist</div>
                        </div>
                        <div className="flex-grow border border-ebbigrey-200 p-2 text-ebbigrey-300 bg-ebbigrey-200 ">
                            <div className="hidden md:block">Done! Now choose what to track</div>
                            <div className="md:hidden">Done!</div>
                        </div>
                    </div>
                </div>
                )}


                {(step == 1) && ( //youtube not configured
                    <div className="flex flex-col w-full items-start gap-2 p-4">
                        <div className='w-full text-center'>
                            <p>
                                Ebbi.Rocks focuses on automation and the goal is:
                            </p>

                            <p>less time on jumping between apps - more time for the knowledge, at least after initial setup 😀</p>

                            <br />


                            <ol className='p-5  list-inside bg-white md:w-1/2 m-auto rounded-md mt-4'>
                                <p className='font-semibold'>When you set everything up, your normal usecase will be:</p>
                                <br />
                                <li>You watch a video.</li>
                                <ChevronDownIcon className="h-5 w-5 text-ebbigrey-400 m-auto" />
                                <li>You decide you really don't want to forget it</li>
                                <ChevronDownIcon className="h-5 w-5 text-ebbigrey-400 m-auto" />
                                <li>You add it to the playlist. No leaving Youtube</li>

                            </ol>

                            <ChevronDoubleDownIcon className="h-5 w-5 relative -top-[8px] text-ebbigrey-400 m-auto" />
                            <p className='text-ebbiblue-100'>Ebbi.rocks takes it from there.</p>

                            <p> On daily basis Ebbi.rocks checks for changes and reminds you of what you need to remember in timely manner.</p>

                            <br />

                            <p>So, let's start with the setup:</p>
                            <br />

                            <AuthorizeYoutube />

                            <br />
                            <br />

                        </div>


                        {/* bravo ... more steps to come. stay calm and follow the instructions.
                            Bojan.Rocks! */}
                        {/* <div className="flex flex-row w-full items-stretch justify-center text-center align-middle gap-10 px-10">
                            <div className="w-1/2 "></div>
                            <div className="w-1/2 text-xs"><p>ebbi.rocks needs a right to write, but we encourage least needed priviledge and to educate - so you can do it yourself now</p></div>
                        </div> */}
                    </div>
                )}


                {(step == 2) && (
                    <div className="flex flex-col w-full items-center gap-2 p-4">
                        <div className='w-full text-center '>

                            <p className='border-b border-ebbigrey-200 pb-3 px-10 text-sm m-auto inline-block mb-4'>Done with this step? <button onClick={syncPlaylists} className='bg-ebbiblue-100 text-ebbilight rounded-md px-2 py-1'>{loading ? "Loading..." : "Click here"}</button> to check.</p>

                            <p>Your Youtube account doesn't have Channel registered and it is needed for Ebbi.Rocks to access playlists.</p>


                        </div>
                        <div className='w-full text-center '>

                            <br />

                            <p>
                                It is easy to create one, with 2-3 clicks...
                            </p>

                            <br />


                            <p className='italic text-lg bg-ebbiyellow bg-opacity-40 p-1 inline'>
                                Go to Youtube, click on your profile picture and then on "Create a channel".
                            </p>

                            <br />
                            <br />
                        </div>
                    </div>
                )}


                {(step == 3) && (
                    <div className="flex flex-col w-full items-center gap-2 p-4">
                        <div className='md:w-3/4 lg:w-1/2 text-center flex flex-col space-y-4'>

                            <p className='border-b border-ebbigrey-200 pb-3 px-10 text-sm m-auto inline-block mb-4'>Done with this step? <button onClick={syncPlaylists} className='bg-ebbiblue-100 text-ebbilight rounded-md px-2 py-1'>{loading ? "Loading..." : "Click here"}</button> to check.</p>

                            <p>You need to create playlist in Youtube. </p>

                            <p>You can call it "Watched", or "Important", or "Remind me" (or any other name you want).</p>

                            <p className='italic text-lg bg-ebbiyellow bg-opacity-40 p-1'>Go to Youtube to watch some important video and click
                                on <YoutubeSaveButton/> button below it. In opened menu choose to create new playlist (private or public). After that come back here and click the button above.</p>


                            <p>For example, you can watch this video and <YoutubeSaveButton/> it to playlist:</p>

                            <a target='_blank' href='https://www.youtube.com/watch?v=Qbz7DC94G2U'><img src="https://img.youtube.com/vi/Qbz7DC94G2U/0.jpg" alt="create playlist" className="m-auto w-[240px]" /></a>

                            <br />
                            <br />
                        </div>
                    </div>
                )}


                {(step > 3) && (
                    <>
                        <div className="flex flex-col md:flex-row w-full items-start gap-2 p-4">

                            <div className="flex flex-col justify-center items-start w-full md:w-1/3 relative">
                                <select className="h-10 w-full rounded-sm border-r-8 border-transparent px-2  text-ebbidark2 outline outline-ebbigrey-200"
                                    value={selectedItem}
                                    onChange={event => setSelectedItem(event.target.value)}
                                >
                                    <option value="">Select a playlist to remember</option>
                                    {playlists.map(item => (
                                        <option key={item.id} value={item.id} disabled={item.is_tracked}>
                                            {item.title} {item.is_tracked ? '(tracked)' : ''}
                                        </option>
                                    ))}
                                </select>
                                <div className='text-sm mt-1'>
                                    Synced <Moment fromNow>{profile.youtube.last_sync_at}</Moment>. <a href="#" className="underline" onClick={syncPlaylists}>Click here</a> to sync now
                                </div>
                            </div>
                            <button
                                className=" px-4 py-2  text-white bg-blue-500 rounded-md hover:bg-blue-600"
                                onClick={trackPlaylist}
                                disabled={selectedItem === '' || tracking}
                            >
                                {tracking ? "Saving" : "Track the playlist"}
                            </button>
                        </div>


                        {/* if not any playlist in the list is tracked show message. use javascript some function */}
                        
                            <div className='w-full p-4'>
                                <div className="text-lg font-bold mb-4">Tracked playlists</div>
                                <div className="flex flex-col w-full">
                                    <div className="flex-row mb-1 w-full hidden md:flex bg-ebbigrey-200 border-ebbigrey-300 border rounded-md p-2 text-center">
                                        <div className="w-1/3 font-semibold text-left">Title</div>
                                        <div className="w-1/6 font-semibold">Number of Videos</div>
                                        <div className="w-1/6 font-semibold">Last Synced</div>
                                        <div className="w-1/6 font-semibold">Include in Mail</div>
                                        <div className="w-1/6 font-semibold">Latest video</div>
                                        <div className="w-1/6 font-semibold">Untrack</div>
                                    </div>
                                    <div className="flex flex-col w-full md:p-2">
                                        {(playlists.some(item => item.is_tracked) ? (
                                            playlists.map((playlist) => {
                                                if (!playlist.is_tracked) {
                                                    return null;
                                                }
                                                return (
                                                    <div key={playlist.id} >
                                                        <div className="flex-row w-full py-1.5 hidden md:inline-flex border-b border-ebbigrey-200 hover:bg-ebbigrey-200 transition-colors text-center">
                                                            <div className="w-1/3 text-left">{playlist.title}</div>
                                                            <div className="w-1/6">{playlist.video_count}{!playlist.last_sync_at && "*"}</div>
                                                            <div className="w-1/6">{playlist.last_sync_at ? <Moment fromNow>{playlist.last_sync_at}</Moment> : "not synced yet*"}</div>
                                                            <div className="w-1/6">
                                                                <Switch
                                                                    checked={playlist.is_included_in_mail}
                                                                    onChange={() => updateIsIncludedInMail(playlist.id, !playlist.is_included_in_mail)}
                                                                    className={`${playlist.is_included_in_mail ? 'bg-blue-600' : 'bg-gray-200'
                                                                        } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                >
                                                                    <span className="sr-only">Include in Mail</span>
                                                                    <span
                                                                        className={`${playlist.is_included_in_mail ? 'translate-x-6' : 'translate-x-1'
                                                                            } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                    />
                                                                </Switch>
                                                            </div>
                                                            <div className="w-1/6">{playlist.latest_video_added ? <Moment fromNow>{playlist.latest_video_added}</Moment> : "-"}</div>
                                                            <div className="w-1/6" onClick={() => untrackPlaylist(playlist.id)}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-600 cursor-pointer inline-block" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div className='md:hidden'>
                                                            <Disclosure defaultOpen={false}>
                                                                {({ open }) => {
                                                                    return (
                                                                        <>
                                                                            <Disclosure.Button className={"flex w-full justify-between bg-ebbigrey-200 px-4 py-2 text-left text-sm font-medium text-ebbidark mt-1 rounded-md " + (open && " rounded-b-none")}>
                                                                                <span>{playlist.title}</span>
                                                                                <ChevronUpIcon
                                                                                    className={`${!open ? 'rotate-180 transform' : ''
                                                                                        } h-5 w-5 text-ebbidark`}
                                                                                />
                                                                            </Disclosure.Button>
                                                                            <Disclosure.Panel className="text-sm text-gray-500 border border-ebbigrey-200 rounded-b-md">
                                                                                <div className="flex flex-row w-full flex-wrap items-center">
                                                                                    <div className="w-1/2 p-2 font-semibold">Number of Videos</div><div className="w-1/2">{playlist.video_count}</div>
                                                                                    <div className="w-1/2 p-2 font-semibold">Last Synced</div><div className="w-1/2">{playlist.last_sync_at ? <Moment fromNow>{playlist.last_sync_at}</Moment> : "not synced yet*"}</div>
                                                                                    <div className="w-1/2 p-2 font-semibold">Include in Mail</div>
                                                                                    <div className="w-1/6">
                                                                                        <Switch
                                                                                            checked={playlist.is_included_in_mail}
                                                                                            onChange={() => updateIsIncludedInMail(playlist.id, !playlist.is_included_in_mail)}
                                                                                            className={`${playlist.is_included_in_mail ? 'bg-blue-600' : 'bg-gray-200'
                                                                                                } relative inline-flex h-6 w-11 items-center rounded-full`}
                                                                                        >
                                                                                            <span className="sr-only">Include in Mail</span>
                                                                                            <span
                                                                                                className={`${playlist.is_included_in_mail ? 'translate-x-6' : 'translate-x-1'
                                                                                                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                                                                                            />
                                                                                        </Switch>
                                                                                    </div>
                                                                                    <div className="w-1/2 p-2 font-semibold">Latest video added at:</div><div className="w-1/2 ">{playlist.latest_video_added ? <Moment fromNow>{playlist.latest_video_added}</Moment> : "-"}</div>
                                                                                    <div className="w-1/2 p-2 font-semibold"><button className='bg-ebbired-100 hover:bg-ebbired-200 text-white rounded-md p-2' onClick={() => untrackPlaylist(playlist.id)}>Remove</button></div>
                                                                                </div>
                                                                            </Disclosure.Panel>
                                                                        </>
                                                                    )
                                                                }}
                                                            </Disclosure>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        ) : (<div className='md:text-center text-ebbired-100 font-semibold'>Not tracking any playlist. Track some playlist from the dropdown above.</div>)
                                        )}
                                    </div>
                                </div>
                            </div>
                        
                    </>
                )


                }
            </div>


        </div>
    )
}




function YoutubeSaveButton() {
    return <span className='not-italic relative bg-ebbigrey-200 bg-opacity-10 font-bold px-1 inline-block'>
        <svg className='w-6 h-6 inline-block relative -top-[1px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path d="M22 13h-4v4h-2v-4h-4v-2h4V7h2v4h4v2zm-8-6H2v1h12V7zM2 12h8v-1H2v1zm0 4h8v-1H2v1z"></path>
        </svg>
        Save
    </span>;
}

