import React, { memo, useMemo } from "react";

import axios from '../util/axios';
import { useAuth } from '../hooks/useAuth';
import { useEffect, useState, useRef, useCallback } from 'react';
import { Switch } from '@headlessui/react';


import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import Moment from 'react-moment';
import { useUserProfile, useUserProfileDispatch } from "../contexts/user";

import { useNavigate } from 'react-router-dom';


export default function Remember() {


    const navigate = useNavigate();

    // call the api to get the data
    const [data, setData] = useState({ periods: [], unit: "day" });

    // opened video state
    const [openedVideo, setOpenedVideo] = useState("");

    const profile = useUserProfile();
    const dispatch = useUserProfileDispatch();
    const auth = useAuth();

    const getTodayData = () => {
        axios.get("/api/today?unit=" + profile.sessionUnit, { headers: { Authorization: "Bearer " + auth.user } }).then((response) => {
            setData(response.data);
        }).catch((error) => {
            alert("Something went wrong, please try again later");
        }
        );
    }

    useEffect(() => {
        getTodayData();
    }, [profile.sessionUnit]);

    // function to update one item in the periods array in the data
    const updatePlaylistItemNotes = (youtubePlaylistItemID, value) => {
        data.periods.map((periodItem) => {
            periodItem.items.map((item) => {
                if (youtubePlaylistItemID == item.content.playlist_item_id) {
                    item.content.video.notes = value;
                }
            })
        });

    }

    // // catch ctrl+v and if it is a youtube url, alert that url
    // const handlePaste = (event) => {
    //     const paste = (event.clipboardData || window.clipboardData).getData('text');
    //     if (paste.includes("youtube.com")) {
    //         alert(paste);
    //     }
    // }

    // // start handling the paste event 
    // useEffect(() => {
    //     document.addEventListener('paste', handlePaste);
    //     return () => {
    //         document.removeEventListener('paste', handlePaste);
    //     }
    // }, []);


    // if youtube is set in profile, go through periods and sum up video items.
    var totalVideos = 0;
    var setupCompleted = false;
    var trackingPlaylists = false;
    if (profile.youtube) {
        data.periods.map((periodItem) => {
            totalVideos += periodItem.items.length + periodItem.coming;
        });
        if (profile.youtube.playlists.length>0) {
            setupCompleted = true;
        }
        if (profile.youtube.playlists.some(item => item.is_tracked)) {
            trackingPlaylists = true;
        }
    }





    return (
        <>

            {openedVideo && <VideoModal openedVideo={openedVideo} setOpenedVideo={setOpenedVideo} updatePlaylistItemNotes={updatePlaylistItemNotes} />}



            {profile.youtube && <Toggle defaultSpacing={profile.defaultSpacing} unit={profile.sessionUnit} setUnit={(unit) => { dispatch({ type: "setSessionUnit", payload: { sessionUnit: unit } }) }} />}

            {profile.youtube ?
                (totalVideos ? data.periods.map(
                    (periodItem) => {
                        return (
                            <div key={periodItem.space + "-period"}>
                                <PeriodHeading period={periodItem.space} unit={data.unit} coming={periodItem.coming} />
                                {(periodItem.items.length == 0) && <NoVideos coming={periodItem.coming} />}
                                <YoutubeTileContainer items={periodItem.items} setOpenedVideo={setOpenedVideo} />
                            </div>
                        )
                    }
                ) : <div className="mx-auto rounded-lg p-5 text-center text-ebbired-100 md:w-1/2">
                    {
                        setupCompleted
                        ?
                        "You will not be reminded about videos in this spacing because you are not tracking playlists or all videos are added long ago."
                        :
                        "Setup is not completed, go to Settings and follow the instructions."
                    }
                    </div>)
                :
                <div className="mx-auto flex max-w-7xl text-ebbidark rounded-lg p-5">
                    <div className="flex flex-col items-center w-full space-y-4">
                        <div className="text-center text-3xl pb-4">
                            Hello there 👋
                        </div>
                        <div className="text-center">
                            Very very welcome to Ebbi.Rocks - <span className="italic text-lg bg-ebbiyellow bg-opacity-40 p-1">small, yet important tool</span> for <span className="italic text-lg bg-ebbiyellow bg-opacity-40 p-1">simple, yet significant technique</span>!
                        </div>
                        <div className="text-center">
                            On this page you will be presented with items to recall - which will help you  <span className="underline font-semibold">a lot</span> to acqurie all the <span className="text-2xl relative top-1">🆒</span> knowledge.
                        </div>

                        <div className="text-center pt-10">
                            <div className="text-center">
                                First step to set this up: <br className="md:hidden" /> go to <button className="bg-ebbiblue-100 hover:bg-ebbiblue-200 p-2 rounded-md text-white" onClick={() => navigate('/settings')}>Settings</button> and authorize access to Youtube.
                            </div>
                        </div>
                        <div className="text-center pt-10 md:w-1/2 text-sm">
                            <div className="text-base">Worried about privacy?</div>
                            <div className="text-4xl">👀</div>
                            <div className="text-left">Ebbi.rocks asks for the least possible permissions needed to access your playlists and reads data only from the playlists you choose to track.  </div>
                            <div className="text-left pt-2">Ebbi.Rocks ensures that the use and transfer of information obtained from Google APIs, including YouTube access, strictly adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy" className="underline" target="_blank">Google API Services User Data Policy</a>. This commitment includes compliance with the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" className="underline" target="_blank">Limited Use requirements</a> outlined by Google to safeguard user data and privacy.</div>
                            <div className="text-left pt-2">If you are specifically worried about your <span className="italic font-semibold">Watch history</span> and <span className="italic font-semibold">Watch Later</span> playlists, <span className="text-ebbired-200">Youtube Data API doesn't allow access to these playlists.</span> <a className="underline" target="_blank" href="https://developers.google.com/youtube/v3/revision_history#january-28,-2021">More here.</a></div>
                        </div>
                    </div>
                </div>
            }


        </>
    )
}

function VideoModal({ openedVideo, setOpenedVideo, updatePlaylistItemNotes }) {

    // const [value, setValue] = useState(openedVideo.video.notes);
    const auth = useAuth();

    // capture the click outside the modal or escape key
    useEffect(() => {
        const listener = (event) => {
            if (event.key === "Escape") {
                setOpenedVideo(null);
            }
        };
        window.addEventListener("keydown", listener);

        // now for click outside of the modal
        const clickListener = (event) => {
            if (event.target.id === "modal-" + openedVideo + "-closer") {
                setOpenedVideo(null);
            }
        };
        window.addEventListener("click", clickListener);

        // disallow scrolling of the background
        document.body.style.overflow = 'hidden';

        return () => {
            window.removeEventListener("keydown", listener);
            window.removeEventListener("click", clickListener);
            document.body.style.overflow = 'unset';
        };


    }, []);

    // // autosave the notes (value) to the api, with debounce
    // const debouncedSave = useCallback(
    //     debounce((newValue) => {
    //         console.log("saving notes" + newValue);
    //         axios.put("/api/youtube/video", { ...openedVideo.video, notes: newValue }, { headers: { Authorization: "Bearer " + auth.user } }).then((response) => {
    //             console.log(response);
    //             updatePlaylistItemNotes(openedVideo.video.youtube_playlist_item_id, newValue);

    //         }).catch((error) => {
    //             alert("Something went wrong, please try again later");
    //         }
    //         );
    //     }, 1000),
    //     []
    // );

    // // save the notes to the api
    // useEffect(() => {
    //     debouncedSave(value);
    // }, [value]);






    return <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div id={"modal-" + openedVideo + "-closer"} className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                        <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                            <VideoIframe videoId={openedVideo.video.youtube_video_id} title={openedVideo.video.title} />
                            <div className="mt-2">
                                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                                    {openedVideo.video.title}
                                </h3>
                                <div className="mt-2">
                                    {/* // description excerpt with "show more" text to open fully */}
                                    <p className="text-sm text-gray-500">
                                        {truncate(openedVideo.video.description, 200)}
                                    </p>
                                </div>

                                <div className="mt-2">
                                    <p className="text-sm text-gray-500">
                                        <b>Added:</b> <Moment fromNow>{openedVideo.published_at}</Moment>
                                    </p>
                                </div>

                                {/* <div className="mt-2">
                                    <ReactQuill theme="snow" formats={['background',
                                        'bold',
                                        'color',
                                        'font',
                                        'code',
                                        'italic',
                                        'link',
                                        'size',
                                        'strike',
                                        'script',
                                        'underline',
                                        'blockquote',
                                        'header',
                                        'indent',
                                        'list',
                                        'align',
                                        'direction',
                                        'code-block',
                                        'formula']} value={value} onChange={setValue} />
                                </div> */}

                            </div>
                        </div>
                    </div>
                </div>

                <div className="bg-ebbigrey-100 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-ebbired-100 text-base font-medium text-white hover:bg-ebbired-200 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm" onClick={() => { setOpenedVideo(null); }}>
                        Close
                    </button>
                </div>
            </div>
        </div>
    </div>;
}

function debounce(func, wait) {
    let timeout;
    return function (...args) {
        const context = this;
        if (timeout) clearTimeout(timeout);
        timeout = setTimeout(() => {
            timeout = null;
            func.apply(context, args);
        }, wait);
    };
};



function truncate(source, size) {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

// YoutubeTiles container component
function YoutubeTileContainer(props) {
    const profile = useUserProfile();

    // list all playlists in profile
    var playlists = profile.youtube.playlists


    var playlistMap = {}
    playlists.forEach((playlist) => {
        playlistMap[playlist.id] = playlist.title
    })

    return (
        <div className="mx-auto max-w-7xl p-2 2xl:px-0 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-2 lg:gap-3">
            {props.items.map((item) => {
                item.content.date = new Date(Date.now() - Math.floor(Math.random() * 60) * 24 * 60 * 60 * 1000);
                return <YoutubeTile key={item.content.playlist_item_id} playlistTitle={playlistMap[item.content.playlist_id]} item={item.content} setOpenedVideo={props.setOpenedVideo} />
            })}
        </div>
    )
}


function Toggle({ defaultSpacing, unit, setUnit }) {


    return (
        <>
            <div className="mx-auto max-w-7xl flex py-3 justify-end items-center px-3 2xl:px-0">
                <span className="text-sm font-bold">Spacing:</span>
                <span className="text-sm px-2">Days</span>
                <Switch
                    checked={unit}
                    onChange={() => setUnit(unit === "day" ? "week" : "day")}
                    //   className={`${weeks ? 'bg-teal-900' : 'bg-teal-700'}
                    className={`bg-ebbiyellow relative inline-flex h-[28px] w-[52px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75 shadow-inner`}
                >
                    <span className="sr-only">Use setting</span>
                    <span
                        aria-hidden="true"
                        className={`${unit === "week" ? 'translate-x-6' : 'translate-x-0'}
              pointer-events-none inline-block h-[24px] w-[24px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out border border-ebbigrey-100`}
                    />
                </Switch>
                <span className="text-sm pl-2">Weeks</span>
            </div>

            <div className="mx-auto max-w-7xl flex justify-end  items-center">
                {defaultSpacing != unit && <span className="text-xs text-yellow-700 border border-yellow-700  bg-yellow-200 bg-opacity-10 rounded-md px-2 py-1 mx-2 sm:mx-0">This setting is not default for your profile, change in Settings if you want to keep it. This does not affect emails settings.</span>}
            </div>
        </>
    )
}


//  YoutubeTile functional component that is in iteration above
function YoutubeTile(props) {

    // detect hover and render different date format
    const [hover, setHover] = useState(false);



    const playlistItem = props.item;
    const playlistID = playlistItem.playlist_id; // TODO: here we have ID
    const video = playlistItem.video;


    // parse date from video.published_at
    const date = new Date(playlistItem.published_at);

    // format string for the random date
    const randomDateString = date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: 'numeric' });
    const randomDateFormatted = <Moment fromNow >{date}</Moment>


    return (
        <div className="max-w-sm bg-white rounded-md touch hover:shadow-lg cursor-pointer overflow-hidden hover:scale-105 transition-transform relative" onClick={() => props.setOpenedVideo(playlistItem)}>

            <div className="relative w-full h-0 rounded-md rounded-b-none" style={{ paddingBottom: "56.25%", backgroundImage: `url(${video.thumbnail_url})`, backgroundPosition: 'center', backgroundSize: '100%' }} />

            <div className="p-3 rounded-b-md border border-ebbigrey-100">
                <h5 className="mb-2 font-bold tracking-tight text-ebbidark line-clamp-2">{video.title}</h5>


                {/* // add date when video is added to the list - don't use props, just random date */}
                <div className="absolute bottom-2 ">


                    <p className="group flex text-sm text-ebbidark dark:text-gray-400 " onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>

                        {!hover ? <span> Added: {randomDateFormatted}</span> : <span> Added: {randomDateString}</span>}

                        {/* <span class="group-hover:opacity-100 transition-opacity bg-gray-800 px-1 text-xs text-gray-100 rounded-md absolute left-1/2 
                        -translate-x-1/2 translate-y-3 opacity-0 m-4 mx-auto text-center w-24 p-1">{randomDateString}</span> */}
                    </p>

                    <p className="text-sm text-ebbidark dark:text-gray-400 line-clamp-1">Playlist: {props.playlistTitle}</p>
                    {/* <p className="text-sm text-ebbidark dark:text-gray-400">Don't remind me anymore</p> */}
                </div>

                <div className=" bottom-0">

                    <p className="text-sm text-ebbidark dark:text-gray-400">{'\u00A0'}</p>
                    <p className="text-sm text-ebbidark dark:text-gray-400">{'\u00A0'}</p>
                    <p className="text-sm text-ebbidark dark:text-gray-400">{'\u00A0'}</p>



                </div>

                {/* 
                <a href={"https://www.youtube.com/watch?v=" + props.item.youtube_video_id} target="_blank" className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    Watch again
                </a> */}
            </div>
        </div>
    )
}

function NoVideos({ coming }) {
    return (
        <div className="mx-auto   max-w-7xl text-blue-500 rounded-lg p-5">
            <div className="text-gray-700 dark:text-gray-400 text-center">No videos found for this period. {coming ? "There are " + coming + " video" + (coming > 1 ? "s" : "") + " coming soon, don't miss reminder." : ""}</div>
        </div>
    )
}

// period heading 
function PeriodHeading(props) {
    return (
        <div className="max-w-7xl border-solid border-b border-slate-300 text-l font-bold mx-2 xl:mx-auto">
            {props.period} {props.unit}{props.period > 1 ? "s" : ""} ago {props.coming ? <span className="text-sm"> | coming {props.coming} videos</span> : ""}
        </div>
    )
}

function IconQuestionCircle(props) {
    return (
        <svg
            viewBox="0 0 1024 1024"
            fill="currentColor"
            height="1em"
            width="1em"
            {...props}
        >
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z" />
            <path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z" />
        </svg>
    );
}


const VideoIframe = props => {
    const { videoId, autoPlay, title } = props
    const videoURL = `https://www.youtube.com/embed/${videoId}${autoPlay ? "?autoplay=1" : ""
        }`
    const iframeRef = useRef(null)
    const defaultHeight = 495
    const [videoHeight, setVideoHeight] = useState(
        iframeRef.current ? iframeRef.current.offsetWidth * 0.5625 : defaultHeight
    )

    const handleChangeVideoWidth = useCallback(() => {
        const ratio =
            window.innerWidth > 990
                ? 1.0
                : window.innerWidth > 522
                    ? 1.2
                    : window.innerWidth > 400
                        ? 1.45
                        : 1.85
        const height = iframeRef.current
            ? iframeRef.current.offsetWidth * 0.5625
            : defaultHeight
        return setVideoHeight(Math.floor(height * ratio))
    }, [])

    useEffect(() => {
        window.addEventListener("resize", handleChangeVideoWidth)
        const ratio =
            window.innerWidth > 990
                ? 1.0
                : window.innerWidth > 522
                    ? 1.2
                    : window.innerWidth > 400
                        ? 1.45
                        : 1.85
        const height = iframeRef.current
            ? iframeRef.current.offsetWidth * 0.5625
            : defaultHeight
        setVideoHeight(Math.floor(height * ratio))
        return function cleanup() {
            window.removeEventListener("resize", handleChangeVideoWidth)
        }
    }, [videoHeight, handleChangeVideoWidth])

    return (
        <iframe
            ref={iframeRef}
            title={title}
            width="100%"
            height={`${videoHeight}px`}
            src={videoURL}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
        />
    )
}
