import * as React from "react";
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";




import { AuthProvider } from '../hooks/useAuth';

import Login from '../pages/Login';
import App from '../pages/App';
import Settings from '../pages/Settings/Settings';
import Remember from '../pages/Remember';
import { UserProfileProvider } from "../contexts/user";

export default function Router() {
  return <RouterProvider router={router} />
}

const router = createBrowserRouter([
  {
    path: "/login",
    element: <AuthProvider><Login /></AuthProvider>
    // errorElement: <ErrorPage />,
    // loader: rootLoader,
    // action: rootAction,
  },
  {
    path: "/",
    element: <AuthProvider><App /></AuthProvider>,
    children: [
      {
        element: <Remember />,
        index: true,
      },
      {
        path: "settings",
        element: <Settings />
        // errorElement: <ErrorPage />,
        // loader: rootLoader,
        // action: rootAction,
      },
    ],
  },
]);


