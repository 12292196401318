import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import React from 'react';
import axios from '../util/axios';
import {useAuth} from '../hooks/useAuth';
import { GoogleOAuthProvider } from '@react-oauth/google';


import { useUserProfile, useUserProfileDispatch } from "../contexts/user";
import { useNavigate } from 'react-router-dom';

import youtubeLogo from './assets/yt_icon_rgb.png';

import ReactGA4 from 'react-ga4';

function MyCustomButton() {
    
    const auth = useAuth();
    const dispatch = useUserProfileDispatch();
    const navigate = useNavigate()
    
    const [loading, setLoading] = React.useState(false);

    const sendToBackend = (codeResponse, token) => { 

        axios.post('/api/youtube/authorize', codeResponse ,{headers: {Authorization: "Bearer "+ token}})
        .then((response) => {

            // send google analytics event for youtube authorization called "authorize_youtube"
            ReactGA4.event('authorize_youtube');


            axios.get('/api/profile', { headers: { Authorization: "Bearer " + auth.user } })
            .then((response) => {
                let youtube = response.data.providers.find(provider => provider.type === "youtube");
                if (youtube) {
                    dispatch({
                        type: "setYoutube", payload: youtube.configuration
                    })
                }
            })
            .catch((error) => {
                // if 401 (unauthorized) then redirect to login
                if (error.response.status === 401) {
                    auth.logout();
                    // googleLogout();
                    navigate('/login');
                }

            })
        }
        )
        .catch((error) => {
            alert("Something went wrong, please try again later");
        }
        )
    }

    const authorize = useGoogleLogin({
        onNonOAuthError: error => {alert("Something went wrong, please try again later"); setLoading(false);},
        onError: error => {alert("Something went wrong, please try again later"); setLoading(false);},
        onSuccess: codeResponse => {sendToBackend(codeResponse,auth.user); setLoading(false);},
        flow: 'auth-code',
        scope: 'https://www.googleapis.com/auth/youtube.readonly',
        // scope: 'https://www.googleapis.com/auth/youtube',
        
    });

    

    


    return (
        
        <button onClick={() => {setLoading(true); authorize()}} className='px-4 py-3 text-ebbidark bg-white border border-ebbigrey-200 hover:border-ebbigrey-300 hover:shadow-sm rounded-md text-sm'>
            <img src={youtubeLogo} className="inline-block w-8 mr-3" />
            {loading ? "Logging in with Youtube ... " : "Login with Youtube"}
        </button>
      
    );
}


export default ()=><GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}><MyCustomButton/></GoogleOAuthProvider>;