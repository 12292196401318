// axios client/instance with default config and interceptors for auth and error handling
import axios from 'axios';

const axiosInstance = axios.create({
    // base url from env if available, if not use localhost
    baseURL: process.env.REACT_APP_API_BASE_URL || 'http://localhost:8080/',
    timeout: 3000,
    headers: {
        'Content-Type': 'application/json',
    },
    });
    
    // axiosInstance.interceptors.request.use(
    // (config) => {
    //     const token = localStorage.getItem('token');
    //     if (token) {
    //     config.headers.Authorization = `Bearer ${token}`;
    //     }
    //     return config;
    // },
    // (error) => Promise.reject(error)
    // );
    
    // axiosInstance.interceptors.response.use(
    // (response) => response,
    // (error) => {
    //     // if 401 (unauthorized) then redirect to login
    //     if (error.response.status === 401) {
    //     localStorage.removeItem('token');
    //     window.location.href = '/login';
    //     }
    //     return Promise.reject(error);
    // }
    // );
    
    export default axiosInstance;